import React from "react"
import { css } from "@emotion/core"
import Layout from "../components/Layout"
import { Arrow } from "../components/common"
import ServiceRow from "../components/rows/ServiceRow"

import BulbImage from "../images/services/bulb.jpg"
import PhoneImage from "../images/services/phone.jpg"
import LaptopImage from "../images/services/laptop.jpg"

const ServicesPage = () => (
  <Layout>
    <div
      css={theme => css`
        margin: ${theme.constants.pageMargin};

        @media (max-width: 1200px) {
          margin: ${theme.constants.mediumpageMargin};
        }

        @media (max-width: 1000px) {
          margin: 0px;
        }
      `}
    >
      <div
        css={css`
          @media (max-width: 1000px) {
            padding: 0 20px;
          }
        `}
      >
        <h1
          css={theme => css`
            font-size: ${theme.constants.largeFontSize}px;
            font-weight: ${theme.constants.demi};
            margin-bottom: 50px;
            position: relative;

            @media (max-width: 880px) {
              font-size: ${theme.constants.mediumFontSize}px;
            }
          `}
        >
          Our Services
        </h1>
        <p
          css={theme => css`
            font-size: ${theme.constants.mediumFontSize}px;
            font-weight: ${theme.constants.extraLight};
            margin-bottom: 100px;
            width: 45%;

            @media (max-width: 1000px) {
              width: 100%;
            }

            @media (max-width: 880px) {
              font-size: ${theme.constants.smallFontSize}px;
              margin-bottom: 20px;
            }
          `}
        >
          Glad to have you here! Let us help you with
        </p>
      </div>
      <ServiceRow
        image={BulbImage}
        title="Startup Product Development"
        description="Are you looking for a team to develop an MVP for your business? Book a 30 minute call with us and we will help you choose the right solution for your idea."
      />
      <ServiceRow
        flip
        top={45}
        image={PhoneImage}
        title="Mobile App Development"
        description="Developing mobile apps, we aim for the highest quality possible including an exceptional design and smooth user experience. We aspire to find the most suitable aid to your company’s business processes by improving loyalty programs, creating taylor made applications."
      />
      <ServiceRow
        image={LaptopImage}
        title="Web Development"
        description="With a focus on user experience, we develop customized, safe and scalable websites and web applications. A main objective of ours is to identify a solution suitable for your business by focusing on creating and improving loyalty programs, creating CMS systems and custom admin sites."
      />
      <Arrow top={450} right={50} />
      <Arrow flip top={1510} left={50} />
    </div>
  </Layout>
)

export default ServicesPage
