import React from "react"
import { css } from "@emotion/core"
import { Image, Animate } from "../common"

const ServiceRow = ({ image, title, description, sub, flip, top = 130 }) => (
  <div
    css={css`
      width: 100%;
      height: 770px;
      position: relative;

      @media (max-width: 1000px) {
        height: inherit;
        margin-bottom: 100px;
      }

      @media (max-width: 600px) {
        margin-bottom: 50px;
      }
    `}
  >
    <Animate type="fade" cascade left={!flip} right={flip} distance="20px">
      <Image
        src={image}
        css={theme => css`
          height: 73%;
          position: absolute;
          box-shadow: ${theme.constants.boxShadow};

          ${flip && "right: 0px;"}

          @media (max-width: 1000px) {
            width: 100%;
            position: relative;
          }
        `}
      />
    </Animate>
    <div
      css={css`
        position: absolute;
        top: ${top}px;
        width: 59%;
        margin-left: ${flip ? "0" : "41%"};

        @media (max-width: 1000px) {
          position: relative;
          margin-top: 30px;
          top: 0px;
          width: 100%;
          padding: 0 20px;
          margin-left: 0px;
        }
      `}
    >
      <div
        css={theme => css`
          font-size: ${theme.constants.semiFontSize}px;
          font-weight: ${theme.constants.demi};
          width: 70%;
          margin-bottom: 50px;

          @media (max-width: 880px) {
            font-size: ${theme.constants.mediumFontSize}px;
            width: 100%;
          }

          @media (max-width: 600px) {
            margin-bottom: 20px;
          }
        `}
      >
        {title}
      </div>
      <div
        css={theme => css`
          font-size: ${theme.constants.mediumFontSize}px;
          font-weight: ${theme.constants.light};
          text-align: left;
          margin-bottom: 60px;
          @media (max-width: 1000px) {
            margin-bottom: 0px;
          }

          @media (max-width: 880px) {
            font-size: ${theme.constants.smallFontSize}px;
          }
        `}
      >
        {description}
      </div>
      <div
        css={theme => css`
          font-size: ${theme.constants.smallFontSize}px;
          font-weight: ${theme.constants.light};
          text-align: left;
          line-height: 1.7;
        `}
      >
        {sub}
      </div>
    </div>
  </div>
)

export default ServiceRow
